@import '../../../../FormBuilder/components/form';
@import '../../../ecom-product-grid.scss';

.filter-accordion-wrapper {
  .filter-content {
    gap: 24px;
    .filter-item-checkbox {
      @extend .checkbox-container-curaleaf;
    }
    .ecom-slider-container {
      width: 100%;
      .ecom-slider {
        @extend .potency-slider;
      }
    }
  }
}
