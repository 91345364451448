@import '../../../styles/variables';

.product-sort-filter {
  .filter-wrapper {
    gap: calc(var(--spacing-unit) * 2);
  }
  .subcategory-filter-bar-mobile {
    display: none;
  }
  .left-side {
    flex: 1;
    min-width: 0;
    .filter-button {
      padding: 8px 23px;
      border: 2px solid var(--primary);
      height: 44px;
    }
    .subcategory-filter-bar-dt {
      flex: 1;
      min-width: 0 !important; // prevent flex-grow to take up more space than available
    }
  }
  .right-side {
    width: 356px;
    .view-button {
      background-color: var(--white);
      padding: 17px 8px;
      height: 56px;
      width: 56px;
      border-radius: 8px;
      border: 2px solid var(--secondary-primary);
      &.selected {
        border: 2px solid var(--primary);
      }
    }
  }
  @include media-breakpoint-down(tablet) {
    .filter-wrapper {
      gap: 24px;
    }

    .left-side {
      .filter-button {
        height: 56px;
        width: 72px;
        .filter-label {
          display: none;
        }
      }
    }
    .right-side {
      width: 208px;
      .view-button-group {
        display: none;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    gap: 24px;
    display: flex;
    flex-direction: column;
    .filter-wrapper {
      gap: 12px;
    }
    .subcategory-filter-bar-dt {
      display: none;
    }
    .subcategory-filter-bar-mobile {
      display: revert;
    }
    .left-side {
      .filter-button {
        width: unset;
        .filter-label {
          display: revert;
        }
      }
    }
  }
}

.filter-sidebar {
  max-width: 495px;
  width: 100%;
  padding-bottom: 154px;
  .p-sidebar-header {
    padding: 24px 48px;
    border-bottom: 1px solid var(--secondary-primary);
    .header {
      width: 100%;
      .filter-close-icon {
        border: none;
        padding-right: 0;
      }
    }
  }
  .p-sidebar-content {
    position: relative;
  }
}
