@import '../../../styles/variables';

.account-banner {
  width: 100%;
  height: 100%;
  padding: 8px calc(var(--spacing-unit) * 2);
  min-height: 120px;
  gap: var(--spacing-unit);
  &.logged-in {
    table-layout: fixed;
  }
  .mobile-loyalty-points {
    display: none;
  }
  &.logged-out {
    justify-content: space-between;
    .left-col {
      order: 1;
      width: calc(100% / 3);
    }
    .center-col {
      order: 2;
      width: calc(100% / 3);
    }
    .right-col {
      column-gap: 10px;
      order: 3;
      width: calc(100% / 3);
    }
  }
  .cta-button {
    display: flex;
    justify-content: center;
    .btn {
      background-color: var(--white);
      border-color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      width: 170px;
      &:hover,
      &:active {
        background-color: var(--secondary);
        color: var(--white);
        border-color: var(--secondary);
      }
    }
  }
  .box-wrapper {
    width: 100%;
    &:first-child {
      padding-right: 10px;
    }
    &:nth-child(2) {
      padding-left: 10px;
      padding-right: 10px;
    }
    &:last-child {
      padding-left: 10px;
    }
  }
  .box {
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
    height: 100%;
    padding: var(--spacing-unit);
  }
  .icon {
    height: 50px;
    width: 50px;
    margin-right: 12px;
  }
  @include media-breakpoint-down(sm) {
    padding: calc(var(--spacing-unit) * 2) var(--spacing-unit);
    .mobile-hide,
    .icon {
      display: none;
    }
    .text-content {
      width: 100%;
    }
    .text-upper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    .mobile-loyalty-points {
      display: revert;
      padding-left: var(--spacing-unit);
      padding-top: 4px;
      .loyalty-points {
        padding: 4px 8px;
      }
    }
    .account-links > a {
      &:first-child {
        padding-right: 8px;
        border-right: 1px solid var(--secondary-primary);
      }
      &:last-child {
        padding-left: 8px;
      }
    }
    .box-wrapper:first-child {
      padding-right: 0;
    }
  }
  @include media-breakpoint-down(xs) {
    min-height: 60px;
    justify-content: center;
    &.logged-out {
      padding: calc(var(--spacing-unit) / 2) var(--spacing-unit);
      .right-col {
        display: none;
      }
      .center-col {
        width: 40%;
      }
      .left-col {
        width: 55%;
      }
      .cta-button {
        min-height: 40px;
        .btn {
          height: 40px;
        }
      }
    }
  }
}
