.specifications-accordion {
  .specifications-heading {
    min-height: 60px;
    .expand-btn {
      border: none;
      background-color: transparent;
      border-radius: 50%;
      height: 22px;
      width: 22px;
      padding: 0;
      transition: all 0.3s;
      &.close {
        transform: rotate(180deg);
      }
      &:hover {
        svg path {
          fill: var(--input-normal);
        }
      }
    }
  }
  .detail-header {
    margin: 1rem 0;
  }
  .content {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    &.active {
      max-height: 100%;
      transition: max-height 1s ease-in-out;
    }
    .product-details {
      gap: var(--spacing-unit);
      .description-section {
        gap: var(--spacing-unit);
        width: 100%;
        .section-header {
          border-bottom: 1px solid #e9e9e9;
        }
        .item {
          gap: var(--spacing-unit);
          border-bottom: 1px solid #e9e9e9;
          .item-name-container {
            .item-name {
              overflow-wrap: break-word;
            }
            .item-bar {
              display: block;
              height: 4px;
            }
            .secondary-item-bar {
              @extend .item-bar;
              background-color: var(--secondary-dark);
            }
          }
        }
        .specs-item {
          display: flex;
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 1rem;
          .effects-list {
            display: flex;
            flex-direction: column;
            .item-value {
              text-align: right;
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
#specifications {
  scroll-margin-top: 140px;
}

.specifications-loader {
  .description-section {
    width: 100%;
    gap: var(--spacing-unit);
    .section-header {
      border-bottom: 1px solid var(--secondary-primary);
    }
    .item {
      width: 100%;
      border-bottom: 1px solid var(--secondary-primary);
    }
    div {
      margin-bottom: 5px;
      width: 100%;
      .item-name,
      .item-bars,
      .item-value {
        width: 33%;
      }
      &:last-of-type {
        margin-bottom: 1em;
      }
    }
  }
}
