.special-offers-tag {
  border: 1px solid var(--secondary-primary);
  .border-wrapper {
    border-left: 6px solid var(--primary);
  }
  .specials-tag-icon {
    padding-top: 5px;
    padding-right: 10px;
  }
  .offer-content {
    width: 100%;
    .special-list-single {
      padding: 0;
      margin: 0;
      list-style: none;
      width: 100%;
    }
    .special-list {
      padding: 0;
      margin: 0;
      width: 100%;
      list-style: none;
      li::before {
        margin-right: 5px;
        content: '\2022';
        color: var(--primary);
      }
    }
  }
}
