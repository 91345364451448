// Note this page of css will be deleted after our a/b test

@import '../styles/variables';
.old-pgp {
  margin-bottom: 20px;
  .pagination {
    height: 56px;
    button:not(:disabled) {
      cursor: pointer;
      &:hover {
        background-color: var(--light-teal);
      }
    }
    button,
    .current-page {
      padding: var(--spacing-unit) 20px;
      height: 100%;
      background-color: var(--white);
      color: var(--primary);
      border: 1px solid var(--light-grey);
      &:disabled {
        color: var(--black);
        background-color: var(--disabled);
      }
    }
    button:first-of-type {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    button:last-of-type {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  button.selected {
    border: 1px solid black !important;
    border-radius: 4px !important;
    background-color: var(--secondary-light) !important;
  }

  .current-page {
    font-size: 16px;
  }

  .ecom-slider-container > div > span.tbody14 {
    font-size: 14px;
  }

  .p-dropdown-label {
    @include media-breakpoint-down(sm) {
      line-height: 24px !important;
    }
    line-height: 20px !important;
  }

  .filter-subtitle {
    font-size: 24px;
  }

  .product-sort-filter > div > a {
    border-radius: 8px !important;
    border: 2px solid var(--secondary-light) !important;
    padding: 16px;
  }
}
